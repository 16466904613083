import React from 'react';
import { Pie } from 'react-chartjs-2';

const ExpensePieChart = ({ data }) => {
  const chartData = {
    labels: data.map(item => item.category),
    datasets: [
      {
        data: data.map(item => item.amount),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { position: 'top' },
      title: { display: true, text: 'Proporción de Gastos' },
    },
  };

  return <Pie data={chartData} options={options} />;
};

export default ExpensePieChart;
