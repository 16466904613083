import React from 'react';
import CostBarChart from './CostBarChart';
import ExpensePieChart from './ExpensePieChart';

const AnalyticsDashboard = () => {
  // Datos simulados para las gráficas
  const propertyCosts = [
    { name: 'Propiedad A', cost: 100000 },
    { name: 'Propiedad B', cost: 150000 },
    { name: 'Propiedad C', cost: 200000 },
  ];

  const expenses = [
    { category: 'Mantenimiento', amount: 5000 },
    { category: 'Servicios Públicos', amount: 3000 },
    { category: 'Otros', amount: 2000 },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <h1>Analytics Dashboard</h1>
      <div style={{ marginBottom: '30px' }}>
        <CostBarChart data={propertyCosts} />
      </div>
      <div>
        <ExpensePieChart data={expenses} />
      </div>
    </div>
  );
};

export default AnalyticsDashboard;
