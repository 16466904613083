import React from 'react';
import { Bar } from 'react-chartjs-2';

const CostBarChart = ({ data }) => {
  const chartData = {
    labels: data.map(item => item.name),
    datasets: [
      {
        label: 'Costos de Propiedades',
        data: data.map(item => item.cost),
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { position: 'top' },
      title: { display: true, text: 'Costos de Propiedades' },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default CostBarChart;
